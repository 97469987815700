<template>
    <div class="post provider btm-menu show-bulletpoints-normal">
        <Breadcrumb :background="true" :content="breadcrumb"/>

        <div class="profile">
            <div class="container-fluid">
                <div class="profile-main">

                    <profile-image-carousel v-if="slides!==null" :slides="slides"/>

                    <div class="profile-main__info full-width">
                        <h5>{{category}}</h5>
                        <share-button v-if="!showShareBtnInTitle"/>
                        <div class="profile-main__info-title">
                            <h3>{{title}}</h3>
                            <share-button v-if="showShareBtnInTitle"/>
                        </div>
                        <router-link class="institution-title" v-if="institutionTitle!==''" :to="'/orte/'+this.institutionId"><p class="mt10 do-not-translate">{{institutionTitle}}</p></router-link>

                        <info-block target="desktop" :content="content" :infos="infoBlockInfos"/>

                    </div>
                </div>
            </div>
        </div>

        <div class="section about">w
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="about-content d-lg-none">
                                    <p v-if="showMoreDescription" v-html="shortDescription"></p>
                                    <p v-else v-html="description"></p>
                                    <button v-if="shortDescription!==''" @click="showMoreDescription = !showMoreDescription" class="btn btn-block mt10 btn-link text-primary">
                                        {{ showMoreDescription ? 'MEHR ANZEIGEN' : 'WENIGER ANZEIGEN' }}
                                    </button>
                                </div>
                                <div class="about-content d-none d-lg-block" v-if="description!==null">
                                    <h5 class="post-heading">Beschreibung</h5>
                                    <p v-html="description"></p>
                                </div>
                            </div>

                            <info-block class="col-12 d-lg-none" :content="content" target="mobile" :infos="infoBlockInfos"/>

                            <div class="col-12" v-if="schlagworte!==null">
                                <div class="about-chips">
                                    <h5 class="post-heading">Schlagworte</h5>
                                    <h4 class="chip" v-for="(item, i) in schlagworte" :key="i">{{ item }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3 ml-auto">
                        <div class="card-simple mb20 mt40 mt-lg-0" v-if="weitereInfos!==''">
                            <h5 class="card-simple-title">Weitere Infos</h5>
                            <p class="text-black" v-html="weitereInfos"></p>
                        </div>

                        <a class="btn btn-block btn-w-icon icon-left btn-secondary" target="_blank" :href="flyerLink" v-if="flyerLink!==null">
                            <span class="material-icons-outlined" aria-hidden="true">file_download</span>
                            Flyer herunterladen
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <institution-angebot-block v-if="content!==null" :content="content"/>

        <div class="section pt-lg-0">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12 col-lg-6">
                        <detail-map v-if="content !== null && address !== null" :content="content" :addressObj="address"></detail-map>
                    </div>

                    <div class="col-12 col-lg-6">
                        <contact-block class="mt40 mt-lg-0" :contentId="content.id" :contact="contactInfo" v-if="contactInfo!=null && content!==null"/>
                    </div>
                </div>
            </div>
        </div>

        <collection-block v-if="content!==null" :content="content"/>

    </div>
</template>

<script>
import Resource from '@/api/resource';
const institutionResource = new Resource('institutions');
import {getSubCategory } from '@/utils/lupe-helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import ShareButton from '@/components/controls/ShareButton.vue';
import { getFieldValues, showLoader, hideLoader, isset, json_decode, getFlyerLink } from '@/utils/helpers';

export default {
    name: 'OrteDetail',
    mixins: [screenSizeMixin],
    components: {
        ShareButton: () => import('@/components/controls/ShareButton.vue'),
        InfoBlock: () => import('@/components/controls/InfoBlock.vue'),
        DetailMap: () => import('@/components/controls/DetailMap.vue'),
        ContactBlock: () => import('@/components/controls/ContactBlock.vue'),
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
        InstitutionAngebotBlock: () => import('./components/InstitutionAngebotBlock.vue'),
        ProfileImageCarousel: () => import('@/components/controls/ProfileImageCarousel.vue'),
    },
    data() {
        return {
            institution: null,
            showMoreDescription: false,
        }
    },
    watch: {
      id(newId, oldId) {
        if (newId!=oldId) {
          this.getInstitution();
          this.isActiveLocation=true;
          this.$scrollTo();
      }
  }
},
created() {
  this.getInstitution();
  this.isActiveLocation=true;
},
computed: {
    content(){
        if(this.institution!==null){
            return this.institution.content;
        }
        return null;
    },
    id() {
        return this.$route.params.id;
    },
    title(){
        if(this.content!=null){
          return this.content.title;
      }
      return "";
  },
  description(){
    var desc = getFieldValues(this.content,'description');
    return desc!=null?desc:'';
},
breadcrumb() {
    if(isset(this.category) && isset(this.subCategory)){
        return "Orte | " + this.category + " | " + this.subCategory; 
    }
    return "Orte";
},
infoBlockInfos(){
    var object = {};
    object.address = this.address;
    object.appointments = getFieldValues(this.content,'appointments');

    object.linkTo = {
        url: getFieldValues(this.content,'url'),
        facebook: getFieldValues(this.content,'facebook'),
        instagram: getFieldValues(this.content,'instagram'),
        twitter: getFieldValues(this.content,'twitter'),
        youtube: getFieldValues(this.content,'youtube'),
    }
    return object;
},
address(){
    var address = getFieldValues(this.content,'adresse');
    if(address!==null && address!==''){
        if(this.category!==null){
            address.category=this.category;
            address.color="#2E55A5";
        }
        return address;
    }
    return '';
},
category(){
    var value = getFieldValues(this.content,'kategorie');
    if(value!=null){
        if(Array.isArray(value) && value.length>0){
            return value[0];
        }
        return value;
    }
    return null;
},
subCategory(){
    if(isset(this.category)){
        return getSubCategory(this.content,this.category);
    }
    return null;
},
teaserImages(){
    var pictures = getFieldValues(this.content,'teaser-bild');
    if(pictures != null){
        if(Array.isArray(pictures)){
            return pictures;
        }
        else{
            return [pictures];
        }
    }
    return null;
},
logo(){
    var logo = getFieldValues(this.content,'logo');
    return json_decode(logo);
},
contactInfo(){
    var contact = getFieldValues(this.content,'kontaktinformationen');
    if(contact !== null && typeof contact === 'object' && !Array.isArray(contact)){
        return contact;
    }
    return null;
},
shortenedUrl(){
    if(this.url!=null){
      return this.url.length>26?this.url.substring(0,26)+' ...':this.url;
  }
},
currentPath(){
    return window.location.href;
},
schlagworte(){
    var value = getFieldValues(this.content,'schlagworte');
    if(value!=null){
        if(!Array.isArray(value)){
            var temp = value;
            value = [temp];
        }
        return value;
    }
    return null;
},
weitereInfos(){
    var value = getFieldValues(this.content,'weitere_infos');
    return value!=null?value:'';
},
flyerLink(){
    return getFlyerLink(getFieldValues(this.content,'flyer_hochladen'),this.$backendUrl);
},
slides(){
    var images = [];
    if(this.logo != null){
        var logo = json_decode(this.logo);
        if(typeof logo === "object" && logo !== null){
            images.push({image:this.$backendUrl+this.logo.path,class:'logo'});
        } 
        else if(logo !== null){
            var logo = this.logo.charAt(0)!="/"?"/"+this.logo:this.logo;
            images.push({image:this.$backendUrl+logo,class:'logo'});
        }
    }

    if(this.teaserImages != null){
        for(var i = 0; i < this.teaserImages.length; i++){
            var image = json_decode(this.teaserImages[i]);
            if(typeof image === "object" && image !== null){
                images.push({image:this.$backendUrl+image.path,class:'teaser-img'});
            }
            else if(image !== null){
              images.push({image:this.$backendUrl+image,class:'teaser-img'});  
          }
      }
  }
  if(images.length == 0){
    return null;
}
return images;
},
showShareBtnInTitle(){
    if(this.$isMobileScreen){
        return true;
    }
    return false;
},
shortDescription() {
    if (this.description.length > this.$shortDescriptionWordCount) {
        this.showMoreDescription = true;
        return this.description.substring(0, this.$shortDescriptionWordCount) + '...';
    }
    return '';
},
},
methods:{
    json_decode,
    getFieldValues, 
    showLoader, 
    hideLoader, 
    getInstitution() {
        this.loader = this.showLoader(this.loader);
        institutionResource.get(this.id)
        .then(response => {
            this.institution = response.data;
        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
      });
    },
}
}
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.provider{

    .mobile--scrolling{
      margin-left: 0px;
      padding-right: 15px;
  }

  .profile{

    .container-fluid{
        @media (max-width: 991px) {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }

        .profile-main__info {

          @media (max-width: 1360px) {
              min-width: 540px;
              margin-right: 60px;
          }

          @media (max-width: 991px) {
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        @media (max-width: 555px) {
            min-width: auto;
        }

        .info-list {
            margin-right: 20px;
        }

        h5 {
            @media (max-width: 991px) {
                margin-bottom: 0px;
            }
        }
    }
}
}
}

</style>